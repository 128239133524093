body{
  background-color: #868686;
}
.stroke-text{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
.btn{
  background-color: rgb(255, 255, 255);
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn:hover{
  cursor: pointer;
}
.blur{
  background: rgb(253, 120, 43, 0.69);
  position: absolute;
  border-radius: 50%;
  filter: blur(130px);
  z-index: -9;
}
